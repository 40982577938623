import 'lodash/isNumber';
import PropTypes from 'prop-types';
import 'common-tags';
import ImmutablePropTypes from 'react-immutable-proptypes';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import { requestOptionsPropType } from '@dbh/request-types';
import 'invariant';
import _uniqueId from 'lodash/uniqueId';
import { DBH_CLIENTS } from '@dbh/api-constants';
import { isServerSideRendering } from '@dbh/environment';
import { ApiRequestError, ApiMaxResponseTimeExceededError } from '@dbh/request-errors';
import _noop from 'lodash/noop';

/*
 *
 * Constants: `@dbh/front-end-api-calls-logs`.
 *
 */const PACKAGE_NAME="@dbh/front-end-api-calls-logs";const EVENT_TYPES={REQUEST:"request",SUCCESS:"success",ERROR:"error"};// We set a default value, to avoid `null` values in the `DB`.
const DEFAULT_RESPONSE_HEADERS={"cached-result":"false"};

const isSSR=isServerSideRendering(),buildRequestDataForLogsApi=withConformsTo("buildRequestDataForLogsApi",[],a=>{let{startTime:b,url:c,fetchOptions:d}=a;const{env:e,maxResponseTimeMs:f,websitePage:g,headers:h}=d,i=!!(null!=h&&h.Authorization),j=new Date,k=PACKAGE_NAME+"/"+j.toISOString()+"-"+j.getTime()+"-"+_uniqueId(),l=EVENT_TYPES.REQUEST,m={requestId:k,eventType:l,startTime:b,request:{url:c,timeoutMs:f,method:d.method||"GET",apiId:d.apiId,isAuthenticated:i},client:{name:isSSR?DBH_CLIENTS.SSR:DBH_CLIENTS.BROWSER,versionCode:"2024-09-28T02_54_51-e9b786065f30ec436eb44351b2035bc88199c19d-production-bitbucket-8316",env:e,websitePage:g}};return {json:m,insertId:k+"-"+l}});/**
 * Returns data in the format accepted by the `API` call logs `API`.
 * @param {Object} options .
 * @param {string} options.startTime The start time of the request.
 * @param {string} options.url The `URL` of the request.
 * @param {Object} options.fetchOptions .
 * @return {Object} .
 */var buildRequestDataForLogsApi$1 = buildRequestDataForLogsApi;

const googleBigQueryApiCallsLogPropType=_noop;var googleBigQueryApiCallsLogPropType$1 = googleBigQueryApiCallsLogPropType;

/**
 * @typedef {Object} BigQueryTableRow .
 * @property {Object} json .
 * @property {string} insertId .
 */const googleBigQueryRowPropType=_noop;var googleBigQueryRowPropType$1 = googleBigQueryRowPropType;

/**
 * @typedef {import('./types').BigQueryTableRow} BigQueryTableRow
 */ /**
 * Send the log entry for an `API` call response to the `API` call logs `API`.
 * @param {BigQueryTableRow} bigQueryRow .
 * @param {Object} options .
 * @param {Object} options.fetchOptions .
 * @param {Immutable.Map} options.requestAttributes .
 * @param {number} options.endTime .
 * @return {Object} .
 */const buildResponseDataForLogsApi=withConformsTo("buildResponseDataForLogsApi",[],(a,b)=>{let{json:c}=a,{responseHeaders:d,requestAttributes:e,endTime:f}=b;const g=e.get("response"),[h,i,j]=["computeTime","totalTime","networkTime"].map(a=>{const b=g.getIn([a,"ms"]);return b}),k=g.get("status"),{requestId:l}=c,m=EVENT_TYPES.SUCCESS,n={...c,eventType:m,response:{statusCode:k,headers:JSON.stringify({...DEFAULT_RESPONSE_HEADERS,...Object.fromEntries(d)})},endTime:f,timings:{computeTimeMs:h,totalTimeMs:i,networkTimeMs:j}};return {json:n,insertId:l+"-"+m}});var buildResponseDataForLogsApi$1 = buildResponseDataForLogsApi;

/**
 * @typedef {import('./types').BigQueryTableRow} BigQueryTableRow
 */ /**
 * Send error logs to the `API` call logs `API`.
 * @param {BigQueryTableRow} bigQueryRow .
 * @param {Object} options .
 * @param {Object} options.fetchOptions .
 * @param {number} options.endTime .
 * @param {Error} options.error .
 * @param {Immutable.Map} options.requestAttributes .
 * @return {Promise} .
 */const buildErrorDataForLogsApi=withConformsTo("buildErrorDataForLogsApi",[],(a,b)=>{let{json:c}=a,{requestAttributes:d,error:e,endTime:f}=b;const{requestId:g}=c,h=d.get("response"),i=h.getIn(["computeTime","ms"]),j=h.getIn(["totalTime","ms"]),k=h.getIn(["networkTime","ms"]),{name:l,message:m,stack:n}=e,o=EVENT_TYPES.ERROR,p={...c,endTime:f,eventType:o,timings:{computeTimeMs:i,totalTimeMs:j,networkTimeMs:k},...(e instanceof ApiRequestError?{response:{statusCode:e.statusCode,headers:JSON.stringify(Object.fromEntries(e.responseHeaders))}}:{}),error:{name:l,message:m,stack:JSON.stringify(n),isTimeoutError:e instanceof ApiMaxResponseTimeExceededError}};// This is used to identify errors resulting from an `API` request,
// particularly when the `API` response is unexpectedly empty.
return {json:p,insertId:g+"-"+o}});var buildErrorDataForLogsApi$1 = buildErrorDataForLogsApi;

/**
 * Returns an array of a `url` and an options Object that can be passed directly
 * to `@dbh/request`.
 * @param {Object} options .
 * @param {string} options.url .
 * @param {Object} options.fetchOptions .
 * @param {Immutable.Map} options.requestAttributes .
 * @param {Object|string?} options.apiResponse .
 * @param {boolean?} options.isEmptyApiResponse .
 * @param {Object?} options.responseHeaders .
 * @param {Object?} options.timings .
 * @param {Object?} options.error .
 * @return {[string, Object]} .
 */const makeRequestsToFrontEndApiCallsLogsApi=withConformsTo("makeRequestsToFrontEndApiCallsLogsApi",["options",PropTypes.exact({url:PropTypes.string.isRequired,fetchOptions:requestOptionsPropType.isRequired,requestAttributes:ImmutablePropTypes.map.isRequired,apiResponse:PropTypes.oneOfType([PropTypes.object,PropTypes.string]),isEmptyApiResponse:PropTypes.bool,responseHeaders:PropTypes.object,error:PropTypes.instanceOf(Error),timings:PropTypes.object})],a=>{let b,{url:c,fetchOptions:d,responseHeaders:e,requestAttributes:f,apiResponse:g,isEmptyApiResponse:h,timings:i,error:j}=a;const{startTime:k,endTime:l}=i,{headers:m,apiCallsLogsUrl:n}=d,o=buildRequestDataForLogsApi$1({url:c,startTime:k,fetchOptions:d});if(g||h){const a=buildResponseDataForLogsApi$1(o,{requestAttributes:f,endTime:l,responseHeaders:e});b=a;}else if(j){const a=buildErrorDataForLogsApi$1(o,{fetchOptions:d,requestAttributes:f,endTime:l,error:j});b=a;}return [n,{headers:m,method:"POST",body:{rows:[b]}}]});var makeRequestsToFrontEndApiCallsLogsApi$1 = makeRequestsToFrontEndApiCallsLogsApi;

export { DEFAULT_RESPONSE_HEADERS, EVENT_TYPES, PACKAGE_NAME, googleBigQueryApiCallsLogPropType$1 as googleBigQueryApiCallsLogPropType, googleBigQueryRowPropType$1 as googleBigQueryRowPropType, makeRequestsToFrontEndApiCallsLogsApi$1 as makeRequestsToFrontEndApiCallsLogsApi };
